import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyDhCClGX2tshKPasYmxjFvH0zV59hmRhag",
	authDomain: "portfolio-house.firebaseapp.com",
	projectId: "portfolio-house",
	storageBucket: "portfolio-house.appspot.com",
	messagingSenderId: "192968111068",
	appId: "1:192968111068:web:4b84d854a31e6b6f03d519",
	measurementId: "G-177L7L6S1E",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

const projects = collection(db, "projects");
const images = collection(db, "images");
const posts = collection(db, "posts");
const tags = collection(db, "tags");

// subcollections
const tagsByPost = (postId = "") => collection(db, "posts", postId, "tags");

export { firebaseApp, projects, images, posts, tags, tagsByPost };
